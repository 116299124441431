/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-slideshow-image/dist/styles.css";

.center-things{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.divider1 {
    margin-top: 20px;
}

#resetSenha2{
    display: none;
}

.centerPassword{
    display:flex;
    align-self: center;
}

#resetbuttons{
    display: flex;
    justify-content: space-between;
}